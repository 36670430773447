<!--
 * @Author: your name
 * @Date: 2021-07-31 13:28:31
 * @LastEditTime: 2023-04-03 20:41:31
 * @LastEditors: yao_wb
 * @Description: In User Settings Edit
 * @FilePath: \fl-pc\src\views\ActiveDetais.vue
-->
<template>
  <div class="page">
    <div class="banner">
      <img
        v-if="$route.query.type == 1"
        src="@/assets/free-course/activebanner.png"
      />
      <img
        v-if="$route.query.type == 2"
        src="@/assets/free-course/classbanner.png"
      />
    </div>
    <div class="course-introduce">
      <img class="course-img" :src="couseInfo.cover" alt="" />
      <div class="introduce-info">
        <div class="introduce-title">
          {{ couseInfo.title }}
        </div>
        <div class="introduce-content">
          <div v-if="couseInfo.startTime">
            <span
              >活动时间：{{ couseInfo.startTime?couseInfo.startTime.split(" ")[0]:'' }}至{{
                couseInfo.endTime?couseInfo.endTime.split(" ")[0]:''
              }}</span
            >
            <span
              >報名時間：{{ couseInfo.applyStartTime?couseInfo.applyStartTime.split(" ")[0] : ''}}至{{
                couseInfo.applyEndTime?couseInfo.applyEndTime.split(" ")[0]:''
              }}</span
            >
          </div>
          <div couseInfo.address>
            <span>地點：{{$route.query.type==2? couseInfo.classroomName:couseInfo.address }}</span>
            <span>講師：{{ couseInfo.teacherName }}</span>
          </div>
        </div>
        <div class="introduce-hint">
          <div class="hint-box">
            非會員價:<span class="price">{{ couseInfo.price }}</span>
          </div>
          <div class="hint-box bg">
            會員價:<span class="price">{{ couseInfo.memberPrice }}</span>
          </div>
          <div class="hint-box">
            <i class="user" /><span
              >{{ couseInfo.applyNumber || 0 }}人報名</span
            >
          </div>
        </div>
      </div>
      <div class="tag-item2" v-if="!nowState">
        <span>{{ nowStateStr }}</span>
      </div>
      <div class="tag-item1" v-else>
        <!--   -->
        <span v-if="$route.type == 2 && !couseInfo.isSubscribe">已訂閱</span>
        <span
          v-if="$route.type == 2 && couseInfo.isSubscribe"
          class="hover"
          @click="dingyue"
          >訂 閱</span
        >
        <span
          @click="pay('购买')"
          class="hover"
          :class="couseInfo.isBuy ? 'pay-btn1' : ''"
          >{{ couseInfo.isBuy ? "已报名" : "付費報名" }}</span
        >
      </div>
     
    </div>

    <div class="main-content">
      <div class="entirety">
        <div class="teacher-title">講師介紹</div>
        <div class="teacher-info" v-if="couseInfo.teacherName">
          <div class="teacherAvatar">
            <img :src="couseInfo.teacherPicture" alt="" />
          </div>
          <div class="info">
            <div class="teacher-name">
              {{ couseInfo.teacherName }}
            </div>
            <p>
              {{ couseInfo.teacherIntroduce }}
            </p>
          </div>
        </div>

        <div class="teacher-title">興趣班詳情</div>
        <div v-if="$route.query.type==2">
          <span>上課時間：{{couseInfo.interestClassTimeVOS[0].startTime}} - {{couseInfo.interestClassTimeVOS[0].endTime}} {{couseInfo.interestClassTimeVOS[0].cycleName}}</span>
        </div>
        <div v-html="couseInfo.introduce" class="teacher-details"></div>
      </div>
      <div class="branch">
        <div class="branch-info" @click="goBranch">
          <div class="branchAvatar">
            <img :src="couseInfo.branchPicture" alt="" />
          </div>
          <div class="info">
            <div class="branch-name">
              {{ couseInfo.branchName }}
            </div>
            <div class="tips">
              <span>{{ couseInfo.branchActNumber || 0 }} </span>活動
              <span style="margin-left: 30px">{{
                couseInfo.branchInterNumber || 0
              }}</span
              >興趣班
            </div>
          </div>
        </div>
        <p>
          {{ couseInfo.sketch }}
        </p>
      </div>
    </div>

    <!-- 会员提示 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>您当前不是會員，是否加入會員享受会员價格？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="pay('不加入')">不加入</el-button>
        <el-button type="primary" @click="$refs.vipbox.dialogVisible = true"
          >加入會員</el-button
        >
      </span>
    </el-dialog>
    <VipDaiolog ref="vipbox"></VipDaiolog>
    <!-- <Pay :price="price" :payUrl="payUrl" ref="paybox" /> -->
    <Chioce
      ref="chioceRef"
      :price="
        userInfo && userInfo.isVip ? couseInfo.memberPrice : couseInfo.price
      "
      :type="Number($route.query.type)"
      :couserId="couseInfo.id"
    />
  </div>
</template>

<script>
import { getAcyiveId, getClassId, getBubscriber } from "@/api/home.js";
import VipDaiolog from "@/components/addPay/index.vue";
import Chioce from "@/components/addPay/chioce.vue";
import moment  from 'moment'
export default {
  components: { VipDaiolog, Chioce },
  data() {
    return {
      couseInfo: {},
      dialogVisible: false,
      userInfo: {},
      payPrice: 0,
      nowState:true,
      isAfter:true,
      nowStateStr:'未开始'
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.getInfo();
  },
  computed: {
    price() {
      if (!this.userInfo) {
        return "";
      }
      return this.userInfo.isVip
        ? this.couseInfo.memberPrice
        : this.couseInfo.price;
    },
  },
  destroyed() {},
  methods: {
    getInfo() {
      const api = this.$route.query.type == 1 ? getAcyiveId : getClassId;
      api(Number(this.$route.query.id)).then((res) => {
        if (res.code == 0) {
          this.couseInfo = res.data;
          // 判断当前课程时间是不是在开始和结束时间之间
          this.nowState = moment().isBetween(this.couseInfo.applyStartTime, this.couseInfo.applyEndTime, null, '()')
          this.isAfter = moment().isAfter(this.couseInfo.applyStartTime)
          if(!this.nowState && !this.isAfter){
            this.nowStateStr = '未开始'
          }else if(this.isAfter && !this.nowState){
            this.nowStateStr = '报名结束'
          }
         

        }
      });
      this.couseInfo.type = Number(this.$route.query.type);
    },
    dingyue() {
      if (!this.userInfo) {
        localStorage.setItem("isLoginType", "login");
        this.$login();
      } else {
        getBubscriber(this.couseInfo.id).then((res) => {
          if (res.code === 0) {
            this.$message.success("订阅成功");
            this.getInfo();
          }
        });
      }
    },
    pay(clicktype) {
      console.log(this.couseInfo);
      console.log(this.userInfo);
      if (!this.userInfo) {
        localStorage.setItem("isLoginType", "login");
        this.$login();
      } else if (this.couseInfo.isBuy) {
        return;
      } else {
        if (clicktype === "购买" && !this.userInfo.isVip) {
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
          this.$refs.chioceRef.show();
        }
      }
    },
    goBranch() {
      this.$router.push({
        path: "/branch",
        query: {
          id: this.couseInfo.branchId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  background-color: #f8f8f8;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 30px;
}

.main-content {
  width: 1200px;
  margin: -100px auto 0;
  text-align: left;
  display: flex;
}

.banner {
  width: 100%;
  height: 250px;

  img {
    width: 100%;
    height: 100%;
  }
}

.course-introduce {
  width: 1200px;
  margin: 0px auto;
  height: 200px;
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transform: translateY(-125px);
  position: relative;

  .course-img {
    display: inline-block;
    width: 213px;
    height: 160px;
    margin-right: 20px;
  }

  .introduce-info {
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    flex: 1;
    padding: 5px 0 0;

    .introduce-title {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
    }

    .introduce-content {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      height: 60px;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      // margin-top: 30px;
      background: #f6f6f6;
      display: flex;
      flex-direction: column;

      div {
        span:last-child {
          margin-left: 40px;
        }
      }
    }

    .introduce-hint {
      display: flex;
      align-items: center;
      width: 100%;

      .hint-box {
        display: flex;
        align-items: center;
        margin-right: 30px;
        font-size: 14px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #444444;

        .price {
          font-family: Arial-Bold, Arial;
          font-weight: bold;
          color: #f5394c;
        }

        .price:before {
          content: "$";
          font-size: 12px;
          margin-right: 3px;
        }
      }

      .bg {
        background: rgba(245, 57, 76, 0.3);
        padding: 5px 10px;
      }

      .hint-box:last-child {
        color: #666666;
      }
    }
  }

  .tag-item1 {
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    span {
      width: 130px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #666666;
      background: #dddddd;
      border-radius: 20px;
      margin-bottom: 20px;
    }

    span:last-child {
      background: #f5394c;
      color: #fff;
      margin-bottom: 0px;
    }
  }
  .tag-item2{
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    span {
      width: 130px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #666666;
      background: #dddddd;
      border-radius: 20px;
      margin-bottom: 20px;
    }
  }
}

.entirety {
  width: 820px;
  margin-right: 20px;
  background: #fff;
  padding: 20px;
}

.branch {
  flex: 1;
  background: #fff;
  border-radius: 8px;
  padding: 30px 20px;
  max-height: 245px;

  .branch-info {
    display: flex;
  }

  img {
    width: 64px;
    height: 64px;
    border: 1px solid #f6f6f6;
    margin-right: 10px;
  }

  .branch-name {
    font-size: 18px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  p {
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #667280;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .tips {
    margin-top: 18px;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #666c80;

    span {
      font-size: 18px;
      color: #333;
    }
  }
}

.teacher-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  margin-bottom: 25px;
  //   &::before{
  //       content: ' ';
  //       display: inline-block;
  //       width: 4px;
  //       height: 16px;
  //       background: #FB3A4E;
  //       margin-right: 10px;
  //   }
}

.teacher-info {
  width: 380px;
  background: #f5f8fa;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: flex-start;
  padding: 25px 15px;
  margin-bottom: 50px;

  img {
    width: 58px;
    height: 58px;
    margin-right: 20px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ccc;
  }

  .info {
    flex: 1;

    .teacher-name {
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 600;
      color: #333333;
      margin-bottom: 23px;
    }

    p {
      color: #666666;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      span {
        color: #333333;
      }
    }
  }
}
.pay-btn1 {
  background: #f8f8f8 !important;
  color: #666 !important;
  cursor: none !important;
}
.teacher-details{
  :deep(img){
    width: 100%;
  }
}
</style>
